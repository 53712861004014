<template>
    <a 
        v-if="document.data.redirect_enabled"
        :href="outLink"      
        target="_blank" 
        rel="nofollow"
    >
        {{ text }} <i v-if="!disableIcon" class="bi bi-chevron-double-right ms-2" />
    </a>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        },
        text: {
            type: String,
            required: false,

            default: function () {
                return this.$translate('cta_to_application', 'Till ansökan');
            }
        },
        disableIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        outLink() {
            if (this.placement && this.position && this.position > 0) {
                return `/out/${this.document.id}/?placement=${this.placement}&position=${this.position}`;
            }
    
            if (this.placement) {
                return `/out/${this.document.id}/?placement=${this.placement}`;
            }
    
            if (this.position && this.position > 0) {
                return `/out/${this.document.id}/?position=${this.position}`;
            }
            return `/out/${this.document.id}/`;
      
        }  
    }
};
</script>